import React from "react";
import './Hero.css';

export default function Hero() {
    return (
        <section
            id="hero"
            className="custom-background"
            style={{
                overflowX: 'hidden',
                paddingTop: '90px',
                paddingBottom: '120px',
            }}
        >
            <div className="container-lg">
                <div className="row justify-content-center mx-0">
                    <div className="col-lg-5 order-md-1 text-center text-lg-start custom-text-align align-self-lg-center">
                        <h1>
                            <div className="mb-3 mt-5 text-white">BuilderCollab</div>
                        </h1>
                        <p className="lead my-4" style={{color: "grey", fontWeight: "bold"}}>The #1 place to connect with other builders and share your creative journey</p>
                        <a
                            className="btn bg-white text-primary-600 hover:bg-primary-800 hover:text-white"
                            href="https://forms.gle/yPnhmbTKrT1U9x5o7"
                            target="_blank"
                            rel="noopener"
                        >
                            Join Waitlist
                        </a>
                    </div>
                    <div className="col-lg-5 order-lg-2 text-center text-lg-start my-5 d-none d-lg-block custom-lg-order">
                        <img src="/images/demo-img.png" alt="Hero" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    );
}





