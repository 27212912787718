import React from 'react';
import './Navbar.css';

export default function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark nav-custom"
      style={{ padding: '30px 0', height: '120px', width: '100%', height: '100%' }}
      data-spy="scroll" 
      data-target="#navbarNav" 
    >
      <a className="navbar-brand ml-4" href="#" style={{ fontSize: '24px', lineHeight: '36px', display: 'flex', alignItems: 'center' }}>
        <img src="/images/Logo.png" alt="Logo" className="img-fluid" style={{ marginRight: '10px' }} />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        style={{ marginRight: '20px' }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse my-3 nav-custom text-white" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#hero">Intro</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#how-it-works how-it-works-container">How It Works</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#benefits benefits-container">Benefits</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#footer">Sign Up</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
