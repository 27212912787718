import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <section id="footer">
            <div className="" style={{ overflowX: 'hidden', paddingLeft: '0', paddingRight: '0', marginLeft: '0', marginRight: '0', width: '100vw', }}>
                <footer>
                    <div className="text-center py-6" style={{ backgroundColor: '#030A14', padding: '60px' }}>
                        <h2 className="text-lg font-semibold text-white " style={{ marginBottom: '40px' }}>Join the Community</h2>
                        <a
                            className="btn bg-white text-primary-600 hover:bg-primary-800 hover:text-white"
                            href="https://forms.gle/yPnhmbTKrT1U9x5o7"
                            target="_blank"
                            rel="noopener"
                        >
                            Join Waitlist
                        </a>
                    </div>
                </footer>


            </div>
        </section>
    );
}