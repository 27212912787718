import './App.css';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Footer from './components/Footer/Footer';
import Benefits from './components/Benefits/Benefits';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="section">
        <Hero />
        <HowItWorks />
        <Benefits />
        <Footer />
      </div>
    </div>
  );
}

export default App;
