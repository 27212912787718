import React from "react";
import './Benefits.css';

export default function Benefits() {
    return (
        <section id="benefits benefits-container" className=" benefit-custom-background" style={{ paddingTop: '50px', paddingBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="container">
            <div className="angular-triangle-decor" style={{ position: 'absolute', left: '0vw'}}>
                <img src="/images/angularTriangleDecor.png" alt="Decor" className="img-fluid"   style={{ width: '280px' }}/>
            </div>
                <h2 className="text-center mb-5" style={{ fontSize: '34px', color: '#fff', textShadow: '0 0 6px rgba(0, 0, 0, 1)' }}>Benefits</h2>
               
                <div className="row justify-content-around " style={{paddingRight: '20px'}}>
              
                    <div className="col-md-4 text-center mb-4">
                        <div className="box-under-benefits">
                            <div className="left-component">
                                Early-bird opportunities to receive funding
                            </div>
                            <div className="right-component">
                                <img src="/images/benefits-funding.png" alt="Funding" className="img-fluid" />
                            </div>
                        </div>
                        <div className="box-under-benefits">
                            <div className="left-component">
                                Rewards/drops from partner companies
                            </div>
                            <div className="right-component">
                                <img src="/images/benefits-rewards.png" alt="Rewards" className="img-fluid" />
                            </div>
                        </div>
                        <div className="box-under-benefits">
                            <div className="left-component">
                                Early access to new features
                            </div>
                            <div className="right-component">
                                <img src="/images/benefits-annoucement.png" alt="Early Access" className="img-fluid" />
                            </div>
                        </div>
                        <div className="box-under-benefits">
                            <div className="left-component">
                                Safe space to share and build your brand
                            </div>
                            <div className="right-component">
                                <img src="/images/benefits-brand.png" alt="Brand" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
