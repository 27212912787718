import React from "react";
import './HowItWorks.css';
import {useState, useEffect} from "react";

export default function HowItWorks() {
    return (
        <section id="how-it-works how-it-works-container" style={{ paddingBottom: '80px',}}>
            <div className="placeholder-image">
                 <img src="/images/newTriangleDecor.png" alt="Triangle" className="triangle-image img-fluid"/>
            </div>
            <div className="">
                <div className="row justify-content-center">
                    <div className="col-lg-5 text-center" style={{ marginBottom: '90px' }}>
                        <h2 className="mb-5 mt-5" style={{ color: '#030A14' }}>How it works</h2>
                        <p className="lead" style={{ marginLeft: 'auto', marginRight: 'auto', color: "grey", fontWeight: "bold" }}>Follow these four steps to forge your builder tribe and share your journey with others</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 text-center mb-4">
                        <div className="mb-3 ">
                            <img src="/images/profile-new.png" alt="Step 1" className="img-fluid" />
                        </div>
                        <h3 className="h5" style={{ color: '#030A14' }}>Step 1: Sign up</h3>
                        <p className="text-muted custom-margin">Sign up and make your profile</p>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center mb-4">
                        <div className="mb-3">
                            <img src="/images/surfprofile.png" alt="Step 2" className="img-fluid" />
                        </div>
                        <h3 className="h5" style={{ color: '#030A14' }}>Step 2: Connect with others based on your needs</h3>
                        <p className="text-muted custom-margin">Forge your builder tribe by finding others that match your interests by looking through profiles and searching your feed</p>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center mb-4">
                        <div className="mb-3">
                            <img src="/images/feed.png" alt="Step 3" className="img-fluid" />
                        </div>
                        <h3 className="h5" style={{ color: '#030A14' }}>Step 3: Build in Public</h3>
                        <p className="text-muted custom-margin">Stay accountable! Share your project journey including ideas, demos, and results to gain feedback and user-base while building your personal brand!</p>
                    </div>
                    <div className="col-lg-3 col-md-6 text-center mb-4">
                        <div className="mb-3">
                            <img src="/images/chat.png" alt="Step 4" className="img-fluid" />
                        </div>
                        <h3 className="h5" style={{ color: '#030A14' }}>Step 4: Message other builders</h3>
                        <p className="text-muted custom-margin">Continue to expand your network by engaging with others with similar goals as you</p>
                    </div>
                </div>
            </div>
        </section>
    );
}



